.betTypesWrapper {
    padding: 32px 0px;
}

.betTypesContainer {
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.betTypeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    background-color: var(--white) !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin: 8px;
    padding: 16px;
}

.betTypeContainer:hover {
    background-color: var(--fifth) !important;
}

.betTypesTitle {
    background-color: var(--purple);
    color: var(--white);
    padding: 8px;
    border-radius: 0px;
}


.betTypes {
    color: var(--white);
    margin-bottom: 4px;
    cursor: pointer;
}



.actionLinksContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.betTypeText {
    margin: 0 !important;
    font-size: 18px;
    padding: 8px 0px;
    color: var(--primary);
}