.ImageAnimationWrapper {
    width: 100%;
    height: 100vh;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.ImageAnimationContainer {
    height: 100%;
}

.animation1,
.animation2,
.animation3 {
    width: 100%;
    height: 100%;
}

.animation1 {
    animation-name: animation1;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.animation2 {
    animation-name: animation2;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.animation3 {
    animation-name: animation3;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes animation1 {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.1;
    }
}

@keyframes animation2 {
    0% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}

@keyframes animation3 {
    0% {
        opacity: 0.7;
    }

    100% {
        opacity: 0.3;
    }
}