.reviewsWrapper {
    padding: 48px 0px;
}

.reviewsImage {
    width: 100%;
    height: 100%;
}

@media screen and (max-width:992px) {
    .reviewsImage {
        display: none;
    }
}

.reviewsCardContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reviewsCard {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 350px;
    height: 400px;
    padding: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.reviewsCardTitle {
    color: var(--primary);
}

.checked {
    color: orange;
    margin: 4px;
}

.reviewsStarsContainer {
    background-color: #ededed;
    padding: 16px;
}

.ratingText {
    font-weight: bold;
    font-size: 14px;
    color: var(--secondary);
}