.tableSportsTitle {
     color: var(--primary);
     margin: 24px 0px;
     padding: 8px;
     border-radius: 8px;
}

.gameTableContainer {
     border-radius: 0px;
     overflow-y: auto;
     padding: 0px;
}

.gameCard {
     padding: 16px;
     margin-bottom: 16px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     background-color: var(--fourth);
}

.gameCard p {
     margin: 0;
     font-size: 12px;
     font-weight: bold;
}

@media (max-width: 300px) {
     .gameCard p {
          font-size: 10px;
     }
 }