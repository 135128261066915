.historiesContainer {
    height: 400px;
    width: 100%;
    overflow-y: auto;
}

.historyTableHead {
    background-color: var(--primary);
    color: var(--white);
}

.historiesContainer::-webkit-scrollbar {
    display: none;
}


@media screen and (max-width:992px) {
    .historyImageContainer {
        display: none;
    }
}