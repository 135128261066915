.footerWrapper {
    background-color: var(--primary);
    color: var(--white);
    height: auto;
}

.linksContainer {
    color: var(--white);
    margin-top: 32px;
    margin-left: 16px;
    font-weight: 600;
}

.socialIcons {
    margin-right: 8px;
}

.copyRight {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    background-color: var(--primary);
    height: 40px;
    font-weight: 600;
}

.socialMedia {
    display: flex;
    /* justify-content: center;
    align-items: center; */
    color: var(--white);
    background-color: var(--primary);
    height: 40px;
    font-weight: 600;
}

.footerText {
    font-size: 14px;
    text-align: left;
    margin-top: 8px
}

.SocialMediaIcons {
    margin-right: 16px;
    cursor: pointer
}

@media screen and (max-width:767.5px) {
    .socialMediaWrapper {
        text-align: center !important;
    }

    .footerText {
        text-align: center;
        margin-top: 16px;
    }
}

.footerLinksContainer {
    display: flex;
}

.footerLinksContainer p {
    color: var(--white);
    margin-right: 8px;
    cursor: pointer;
}