.newsImage {
    margin-bottom: 8px;
    display: block;
    width: 100%;
    height: 100vh;
}

.newsContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: relative;
    height: 100vh;
}

.newsBody {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    color: white;
    font-size: 20px;
    padding: 20px;
    opacity: 1;
}

.newsBodyTitle {
    color: var(--white);
}

.newsDate {
    padding: 8px 0px;
    font-size: 12px;
    margin: 0;
}

.newsReadMoreButton,
.newsReadMoreButton:hover,
.newsReadMoreButton:focus {
    width: 100%;
    border-radius: 0px !important;
    margin: 8px 0px;
    background-color: var(--secondary);
    border: none;
}

.newsReadMoreButton a,
.newsReadMoreButton:hover a,
.newsReadMoreButton:focus a {
    color: var(--white) !important;
    text-decoration: none !important;
}

.slideShow {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mySwiper {
    width: 100%;
    height: 100vh;
}

.swiper {
    width: 80%;
    height: 100vh;
}