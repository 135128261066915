.aboutCard{
    background-color: red;
    margin: 20px;
    /* padding: 5px; */
    border-radius: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cardImg{
    width:100%;
    height: 100%;
    
    border-radius: 10px;
}