
.usaLeagueContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px;
    width: 100%;
    margin-bottom: 16px;
    padding: 8px 16px;
    cursor: pointer;
}

.usaLeagueContainer:hover {
    background-color: var(--third);
}

.usaLeagueText {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0 !important;
    font-weight: bold;
    margin-left: 16px;
    color: var(--primary) !important;
}
.line2{
    width: 35%;
    height: 1px;
    background-color: var(--primary) ;
    display:inline-block;
    margin-bottom: 1vh;
}
.nameTitle2 {
    padding: 8px;
    color: var(--primary);
    /* font-weight: bold; */
    display:inline-block;
    width:30%;
    font-size: 25px;
    text-align: center;
}
@media (max-width: 990px) {
    .nameTitle2 {
        font-size: 22px;
    }
}
@media (max-width: 900px) {
    .nameTitle2 {
        font-size: 20px;
    }
}
@media (max-width: 800px) {
    .nameTitle2 {
        font-size: 20px;
    }
    .line2{
        width: 30%;
    }
    .nameTitle2 {
        width: 40%;
    }
}
@media (max-width: 300px) {
    .nameTitle2 {
        font-size: 18px;
    }
}
