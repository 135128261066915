.ratingSliderWrapper {
    display: flex;
}

.ratingSliderStar {
    font-weight: bold;
    font-size: 16px;
    color: var(--third);
    margin-right: 8px;
}

.ratingSliderContainer {
    width: 200px;
    height: 20px;
    background-color: #f3f3f3;
    border-radius: 100px;
    margin-top: 4px;
}

.ratingSlider {
    background-color: orange;
    border-radius: 100px;
    height: 20px;
}

.ratingTextWidth {
    margin-left: 4px;
    font-size: 12px;
    margin-top: 4px;
    font-weight: bold;
}